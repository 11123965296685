ul{
	list-style: none;
}
.hayhar-page,.m1-002,.m1-003,.m1-004,.m1-005,.m1-006{
	.text-top{
		display: none !important;
	}
	.ant-carousel{
		.slick-list{
			.slick-track{
				display: flex;
			}
			.img_wrap{
				img{
					object-fit:initial !important;
				}
			}
		}
		
	}
	
}
.hayhar-page,.m1-001,.m1-002,.m1-003,.m1-004,.m1-005,.m1-006{
	.ant-carousel{
		height: 730px;
	}
	position: relative;
	.img_wrap{
		img{
			object-fit:cover;
			height: 100%;
			width: 100%;
			vertical-align: middle;
		}
		height: 730px;
	}
	.mobile{
		display:none;
		
		&.top{
			display: none;
		}
		
	}
	overflow: hidden;
	.top.web{
		input{
			height: 2rem;
		}
	}
	input{
		height: 2.65rem;
		border-radius:.25rem;
		padding: .25rem .3rem;
		box-sizing: border-box;
		outline-style:none;
		outline-width: 0px;
		border:2px solid transparent;
		text-shadow: none;
		-webkit-appearance:none;
		-webkit-user-select:text;
		outline-color: transparent;
		box-shadow: none;
		text-overflow:ellipsis;
	}
	.top{
		padding: 1em;
		background-color: rgb(56, 189, 248);
		display: flex;
		justify-content: space-between;
		align-items: center;
		.qrp-header-logo{
			width: 5rem;
		}
		
		// 输入框
		.web-input-box{
			position: relative;
			display: flex;
			align-items: center;
			.web-input{
				
				&:focus{
					box-shadow: 0 0, #93c5fd;
					border-color:#93c5fd;
					
				}
			}
			.web-input+.web-input{
				margin-left: .5rem;
				width: 15rem;
				padding-right: 5rem;
				// margin-right: 5rem;
			}
			.web-label{
				position: absolute;
				top: .5rem;
				right:9.6rem;
				color: inherit;
				text-decoration:inherit;
				font-size: .75rem;
				line-height: 1rem;
				cursor: pointer;
				padding-left: .3rem;
				border-left: 1px solid rgba(229,231,235,1);
				color: rgba(56,189,248,1);
				&:hover{
					color: rgba(56,189,248,1);
				}
			}
			
			// 登录按钮
			.web-login{
				padding: 0.25rem 1rem;
				margin-left: .3rem;
				background-color: #FFF;
				border:none;
				height: 2rem;
				border-radius: .25rem;
				border: 1px solid transparent;
				&:hover{
					border-color: #FFF;
					background-color: rgb(56, 189, 248);
					color: #FFF;
				}
			}
			.country-icon{
				margin-left: .6rem;
				border-left: 1px solid #FFF;
				padding-left: .6rem;
			}
			
		}
	}
	
	
	.ant-input{
		color: #000;
	}

	.error-hit{
		display: flex;
		margin-bottom: 1rem;
		font-size: 13px;
		p{
			color: #e40771;
			width: 50%;
			text-align: left;
		}
	}

	// 中间内容
	.central-web{
		// background: url(/static/images/hayhar/QRP-NUFC-Desktop.jpg) no-repeat center ;
		// background-size:100%;
		overflow: hidden;
		// position: relative;
		.text-top{
			display: flex;
			flex-direction: column;
			align-items: center;
			margin: .8rem auto;
			position: absolute;
			top: 4.55rem;
			left: 30%;
			h2{
				font-size: 3rem;
			}
			p{
				font-size: 2rem;
			}
		}
		.central-box{
			// position: absolute;
			border-radius: .5rem;
			overflow: hidden;
			background-color: #FFF;
			margin: 5% 0 0;
			width: 600px;
			position: absolute;
			top: 12rem;
			right: 5%;
			.tips{
				color:#9ca3af ;
				margin: .75rem auto 0;
				text-align: center;
				
			}
			.heading{
				padding:1rem 0;
				background-color: rgb(56, 189, 248);
				text-align: center;
				color: #FFF;
			}
			.form-modal{
				padding: 1.25rem;
				padding-bottom: 0;
				input{
					border: 1px solid rgb(209,213,219);
					-webkit-box-shadow: inset 0 1px 1px rgba(0, 0, 0, .075), 0 0 8px rgba(209,213,219 .6);
					box-shadow: inset 0px 1px 1px rgba(0, 0, 0, .075) , 0px 0px 8px rgba(209,213,219 .6);
					&:focus{
						border:2px solid rgb(56, 189, 248);
					}
				}
				.namePass{
					display: flex;	
					justify-content: space-between;
					margin-bottom: 1rem;
					input{
						width: 49%;
					}
				}
				.error-hit{
					display: flex;
					margin-bottom: 1rem;
					font-size: 13px;
					p{
						color: #e40771;
						width: 50%;
						text-align: left;
					}
				}
				.email-input{
					display: flex;
					margin-bottom: 1rem;
					input{
						width: 100%;
					}
					
				}
				.phone-number{
					display: flex;
					justify-content: space-between;
					input.phone{
						width: 20%;
						color: #9ca3af;
						cursor: no-drop;
					}
					input.phoneNumber{
						width: 78%;
					}
				}
			}
			.submit{
				margin: 1rem auto;
				padding: 8px 16px;
				display: flex;
				justify-content: center;
				background-color: #0ea5e9;
				border: none;
				border-radius: .25rem;
				color: #FFF;
				font-size: .95rem;
				&:hover{
					background-color: rgb(56,189,248);
				}
			}
		}
	}
	
	
	// 底部内容
	.footer-content-web{
		
		.partner{
			padding: 1rem;
			background-color: #e1e1de;
			display: flex;
			flex-wrap: wrap;
			.item{
				width: 33.333333%;
				display: flex;
				flex-direction: column;
				align-items: center;
				.title{
					margin-bottom: 1.25rem;
					border-bottom: 1px solid;
					font-weight: bold;
					color: #8e8e8e;
				}
				.image-box{
					display: flex;
					justify-content: space-between;
					& > a{
						display: flex;
						flex-direction: column;
						justify-content: center;
						align-items: center;
						text-decoration: none;
						& +a{
							margin-left: 10px;
						}
					}
					
					& .default{
						width: 8rem;
						height: 9rem;
					}
					.footer-image-box-text{
						display: flex;
						flex-direction: column;
						font-size: .75rem;
						text-align: center;
						.divider {
							border: 1px solid #8c8c8c;
							margin: 0.5rem 0;
						}
						a{
							color: #8c8c8c;
							text-decoration: none;
							font-size: 14px;
						}
					}
					.partner-item-0{
						background: url('/static/images/hayhar/tottenham.png') no-repeat center;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/tottenham-hover.png') no-repeat center;
							background-size: cover;
						}
					}
					.parrtner-item-1{
						background: url('/static/images/hayhar/newcastle.png') no-repeat center;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/newcastle-hover.png') no-repeat center;
							background-size: cover;
						}
					}
					
				}
			}
			// 品牌形象大使
			.brand {
				.brand-default{
					width: 9.25rem;
					height: 9.375rem;
				}
				.brand-item-0 {
					background: url(/static/images/hayhar/robbie-sign.png) no-repeat center center;
					background-size: cover;
					&:hover{
						background: url(/static/images/hayhar/robbie-sign-hover.png) no-repeat center center;
						background-size: contain;
					}
				}
				.brand-item-1 {
					background: url(/static/images/hayhar/steve_nash-sign.png) no-repeat center center;
					background-size: cover;
					&:hover{
						background: url(/static/images/hayhar/steve_nash-sign-hover.png) no-repeat center center;
						background-size: contain;
					}
				}
			}
			// 供应商
			.suppliers{
				.suppliers-public-style{
					margin-top:calc(1rem*(1 - 0));
					// margin-right: calc(0.75rem*0);
					// margin-left: calc(0.75rem*(1 - 0));
				}
				.suppliers-box{
					display: flex;
					justify-content: center;
					flex-wrap:wrap;
				}
				.suppliers-default{
					width: 6.5rem;
					height: 3rem;
				}
				.suppliers-item-0{
					margin-top: 1rem;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -6.18rem -47px;
					background-size: 383px;

					// background: url('/static/images/hayhar/AG_Unhover.png')no-repeat center;
					
					
					&:hover{
						background: url('/static/images/hayhar/AG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-1{
					background: url('/static/images/hayhar/Microgaming_Unhover.png')no-repeat center;
					background-size: 100%;
					flex-basis: 33%;
					&:hover{
						background: url('/static/images/hayhar/Microgaming_Hover.png')no-repeat center;
						background-size: 100%;
					}
				}
				.suppliers-item-2{
					background: url('/static/images/hayhar/AG_Unhover.png')no-repeat center;
					background-size: 100%;
					flex-basis: 33%;
					&:hover{
						background: url('/static/images/hayhar/AG_Hover.png')no-repeat center;
						background-size: 100%;
					}
				}
				.suppliers-item-3{
					background: url('/static/images/hayhar/Betfair_Unhover.png')no-repeat center;
					background-size: 100%;
					flex-basis: 33%;
					&:hover{
						background: url('/static/images/hayhar/Betfair_Hover.png')no-repeat center;
						background-size: 100%;
					}
				}
				.suppliers-item-4{
					background: url('/static/images/hayhar/Oneworks_Unhover.png')no-repeat center;
					background-size: 100%;
					flex-basis: 33%;
					&:hover{
						background: url('/static/images/hayhar/Oneworks_Hover.png')no-repeat center;
						background-size: 100%;
					}
				}
				.suppliers-item-5{
					background: url('/static/images/hayhar/PT_Unhover.png')no-repeat center;
					background-size: 100%;
					flex-basis: 33%;
					&:hover{
						background: url('/static/images/hayhar/PT_Hover.png')no-repeat center;
						background-size: 100%;
					}
				}
				.suppliers-item-6{
					background: url('/static/images/hayhar/Gold_Unhover.png')no-repeat center;
					background-size: 100%;
					flex-basis: 33%;
					&:hover{
						background: url('/static/images/hayhar/Gold_Hover.png')no-repeat center;
						background-size: 100%;
					}
				}
				.suppliers-item-7{
					// background: url('/static/images/hayhar/JILI_Unhover.png')no-repeat center;
					// background-size: cover;
					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -18.5rem -259px;
    				background-size: 574px;
					&:hover{
						background: url('/static/images/hayhar/JILI_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-8{
					// background: url('/static/images/hayhar/Joker_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -0.8rem -119.9px;
    				background-size: 410px;
					&:hover{
						background: url('/static/images/hayhar/Joker_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-9{
					background: url('/static/images/hayhar/KingPoker_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/KingPoker_Hover.png')no-repeat center;
						background-size: cover;
					}
				}

				.suppliers-item-10{
					background: url('/static/images/hayhar/KM_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/KM_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-11{
					// background: url('/static/images/hayhar/Microgaming_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -4.3rem -209.9px;
					background-size: 410px;

					&:hover{
						background: url('/static/images/hayhar/Microgaming_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-12{
					// background: url('/static/images/hayhar/PG_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -14.9rem -119.9px;
    				background-size: 410px;

					&:hover{
						background: url('/static/images/hayhar/PG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-13{
					// background: url('/static/images/hayhar/Playtech_Unhover.png')no-repeat center;
					// background-size: cover;

					background: url(/static/images/xuebitu.png) no-repeat center center;
					background-position: -22.5rem -252px;
    				background-size: 574px;

					&:hover{
						background: url('/static/images/hayhar/Playtech_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-14{
					background: url('/static/images/hayhar/PNG_Unhover.png')no-repeat center;
					background-size: cover;

					// background: url(/static/images/xuebitu.png) no-repeat center center;
					// background-position: -22.5rem -252px;
    				// background-size: 574px;

					&:hover{
						background: url('/static/images/hayhar/PNG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-15{
					background: url('/static/images/hayhar/PP_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/PP_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-16{
					background: url('/static/images/hayhar/SABA-SEA_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/SABA-SEA_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-17{
					background: url('/static/images/hayhar/SPalace_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/SPalace_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-18{
					background: url('/static/images/hayhar/Spribe_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/Spribe_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-19{
					background: url('/static/images/hayhar/TCGaming_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/TCGaming_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-20{
					background: url('/static/images/hayhar/TFG_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/TFG_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-21{
					background: url('/static/images/hayhar/V2_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/V2_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
				.suppliers-item-22{
					background: url('/static/images/hayhar/WMCasino_Unhover.png')no-repeat center;
					background-size: cover;
					&:hover{
						background: url('/static/images/hayhar/WMCasino_Hover.png')no-repeat center;
						background-size: cover;
					}
				}
			}
		}
		/* 版权、 pay 、安全、责任*/
		@media (max-width:1023px){
			.copyright-pay-safe{
				display: flex;
				flex-direction: column;
				justify-content: center;
				
				.bank-pay,.responsibillity,.safe,.copyright{
					width: 100% !important;
					justify-content: center;
				}
			}
		}
		
		.copyright-pay-safe{
			display: flex;
			padding: .5rem;
			background-color: rgba(229,231,235,1);
			box-sizing: border-box;
			justify-content: center;
			h6{
				white-space: nowrap;
			}
			.copyright,.safe,.responsibillity{
				width: 16.666667%;
				display: flex;
				align-items: center;
				flex-wrap: wrap;
				&.copyright .icon{
					width: 1.6875rem;
					height: 2.0625rem;
					margin-left: 0.625rem;
					background: url(/static/images/hayhar/first-cagayan.png) no-repeat 0 0;
					background-size: cover;
					margin: 0 .25rem;
					&:hover{
						background: url(/static/images/hayhar/first-cagayan-hover.png) no-repeat 0 0;
						background-size: cover;
					}
				}
				&.copyright .icon2{
					width: 34px;
					height: 34px;
					margin-left: 0.625rem;
					background: url(/static/images/hayhar/pagcor.png) no-repeat 0 0;
					background-size: cover;
					margin: 0 .25rem;
					&:hover{
						background: url(/static/images/hayhar/pagcor-hover.png) no-repeat 0 0;
						background-size: cover;
					}
				}
				&.safe .icon{
					width: 5.375rem;
					height: 2.0625rem;
					background: url(/static/images/hayhar/iovation.png) no-repeat 0 0;
					background-size: cover;
					margin-left: 0.625rem;
					&:hover{
						background: url(/static/images/hayhar/iovation-hover.png) no-repeat 0 0;
						background-size: cover;
					}
				}
				&.responsibillity>div{
					display: flex;
					flex-wrap: wrap;
				}
				&.responsibillity .icon{
					width: 2.0625rem;
					height: 2.0625rem;
					margin-left: 0.625rem;
					&.icon.icon-1{
						background: url('/static/images/hayhar/game-card.png') no-repeat 0 0;
						background-size: cover;
						&:hover{
							background: url('/static/images/hayhar/game-card-hover.png') no-repeat 0 0;
							background-size: cover;
						}
					}
					&.icon.icon-2{
						background: url('/static/images/up-18.png') no-repeat 0 0;
						background-size: cover;
						&:hover{
							background: url('/static/images/up-18-hover.png') no-repeat 0 0;
							background-size: cover;
						}
					}
				}
			}
			
			
			// 付款方式
			.bank-pay{
				display: flex;
				flex-grow:1;
				align-items: center;
				flex-wrap: wrap;
				.icon{
					width: 50px;
					height: 33px;
					margin: 0 0.3125rem;
				}
				.icon-1{
					background: url('/static/images/hayhar/visa.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/visa-hover.png') no-repeat 0 0;
					}
				}
				.icon-2{
					background: url('/static/images/hayhar/master.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/master-hover.png') no-repeat 0 0;
					}
				}
				.icon-3{
					background: url('/static/images/hayhar/china-payment.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/china-payment-hover.png') no-repeat 0 0;
					}
					width: 65px;
				}
				.icon-4{
					width: 60px;
					background: url('/static/images/hayhar/fastbank.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/fastbank-hover.png') no-repeat 0 0;
					}
				}
				.icon-5{
					width: 60px;
					background: url('/static/images/hayhar/bankwire.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/bankwire-hover.png') no-repeat 0 0;
					}
				}
				.icon-6{
					width: 60px;
					background: url('/static/images/hayhar/alipay.png') no-repeat 0 0;
					&:hover{
						background: url('/static/images/hayhar/alipay-hover.png') no-repeat 0 0;
					}
				}
			}
		}
		
		.montenegro{
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			padding: .5rem;
			background: rgb(243,244,246);
			img{
				margin: 10px auto;
			}
			p{
				margin-bottom: 0.625rem;
				&.text-gray-500{
					color: rgb(107,114,128,);
					font-size: .75rem;
					text-align: center;
					width: 56%;
				}
			}
		}
		
		
		// end-page
		.end-page{
			background-color: rgb(14,165,233);
			padding: 1rem;
			.end-page-list{
				display: flex;
				justify-content: center;
				font-size: .875rem;
				color: #FFF;
				.item{
					margin: 0 1.25rem 0 0;
					cursor: pointer;
				}
			}
			.help-contact{
				display: flex;
				justify-content: center;
				align-items: center;
				margin-top: 2.725rem;
				.help{
					a {
						color: white;
						text-decoration: none;
					}
					display: flex;
					align-items: center;
					color: #FFF;
					cursor: pointer;
					img{
						margin-right: 0.5rem;
					}
					span{
						border-right: 1px solid #FFF;
						padding-right: 15px;
					}
				}
				.contact-box{
					display: flex;
					align-items: center;
					margin-left: 15px;
					
					.item{
						width: 2.7rem;
						height: 33.7px;
						margin: auto 1rem;
						cursor: pointer;
						&:nth-child(1){
							background: url('/static/images/hayhar/footer-wechat.png');
							background-size: cover;
							&:hover{
								background: url('/static/images/hayhar/footer-wechat-hover.png');
								background-size: cover;
							}
						}
						&:nth-child(2){
							background: url('/static/images/hayhar/footer-weibo.png');
							background-size: cover;
							&:hover{
								background: url('/static/images/hayhar/footer-weibo-hover.png');
								background-size: cover;
							}
						}
						&:nth-child(3){
							background: url('/static/images/hayhar/footer-youku.png');
							background-size: cover;
							&:hover{
								background: url('/static/images/hayhar/footer-youku-hover.png');
								background-size: cover;
							}
						}
						&:nth-child(4){
							background: url('/static/images/hayhar/footer-youtube.png');
							background-size: cover;
							&:hover{
								background: url('/static/images/hayhar/footer-youtube-hover.png');
								background-size: cover;
							}
						}
					}
					.country{
						width: 2rem;
						height: 1.375rem;
						border-left: 1px solid #FFF;
						padding-left:15px ;
						cursor: auto;
					}
				}
			}
			.end-page-footer-text{
				display: flex;
				align-items: center;
				justify-content: center;
				flex-direction: column;
				p{
					color: #FFF;
					margin-top: 0.9375rem;
					font-size: .725rem;
				}
			}
		}
	}
	
	
	// 社交按钮
	.social-web{
		position: absolute;
		transform: translate(50%, -50%);
		top:28.5rem;
		right: -1.2%;
		.social-item{
			text-decoration: inherit;
			cursor: pointer;
			display: flex;
			align-items: center;
			background-color: rgb(2,132,199);
			border-radius: 10px 0 0 10px;
			margin: .5rem 0;
			box-shadow: 0 0 #000 , 0 0 #0000 , 0 4px 6px -1px rgba(0,0,0,0.1),0 2px 4px -1px rgba(0,0,0,0.06);
			padding:0.3rem;
			white-space:nowrap;
			color: #FFF;
			width: 100%;
			width: 150px;
    		height: 50px;
			&:hover{
				transform: translate(-90px,0);
				transition-duration:.3s;
			}
			img{
				height: 2.5rem;
				margin-right: .95rem;
			}
		}
	}
	
}

@import url('./mobile.less');